import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { StarIcon, RepoIcon, GitPullRequestIcon } from "@primer/octicons-react";

import { HeroBlock, Layout } from "~components";
import { Link, Icon, Img, truncate } from "~utils";

const MAX_LENGTH = 125;

export const query = graphql`
  query Contributions($headerImg: String) {
    ...HeaderImg
    github: allGithubViewer {
      edges {
        node {
          login
          collection: contributionsCollection {
            prByRepo: pullRequestContributionsByRepository {
              repo: repository {
                nameWithOwner
                description
                stargazers {
                  totalCount
                }
                owner {
                  login
                  avatarUrl
                }
              }
              contributions {
                edges {
                  node {
                    pr: pullRequest {
                      merged
                      mergedAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @type {GatsbyTypes.PageDataFragment['page']}
 */
const page = {
  frontmatter: {
    title: "My OSS Contributions",
    subtitle: "All the OSS contributions I've made over the years",
    keywords: [],
    template: "listing",
    noindex: false,
    mock: true,
    canonical_url: undefined,
  },
  fields: {
    url: "/contributions/",
  },
};

/**
 * @param {{
 *   data: GatsbyTypes.ContributionsQuery
 * }} props
 */
export default function ContributionsPage({ data: contributionsData }) {
  const { onlyMerged, viewer, data } = useMemo(() => {
    const { github, ...rest } = contributionsData;
    const viewer = github.edges[0].node.login;
    const onlyMergedMap = github.edges.reduce((acc, { node }) => {
      node.collection.prByRepo.forEach(({ contributions, repo }) => {
        const mergedContributions = contributions.edges
          .filter(({ node }) => node.pr.merged)
          .map(({ node }) => new Date(node.pr.mergedAt))
          .sort()
          .reverse();

        if (!mergedContributions.length || repo.owner.login === viewer) return;
        if (!acc[repo.nameWithOwner]) {
          acc[repo.nameWithOwner] = {
            repo,
            contributions: mergedContributions,
            date: mergedContributions[0],
          };
          return;
        }

        acc[repo.nameWithOwner].contributions = acc[
          repo.nameWithOwner
        ].contributions
          .concat(mergedContributions)
          .sort()
          .reverse();

        acc[repo.nameWithOwner].date = acc[repo.nameWithOwner].contributions[0];
      });

      return acc;
    }, {});

    return {
      onlyMerged: Object.values(onlyMergedMap).sort((repoA, repoB) => {
        if (repoB.date - repoA.date === 0) {
          return repoB.contributions - repoA.contributions;
        }
        return repoB.date - repoA.date;
      }),
      viewer,
      data: rest,
    };
  }, [contributionsData]);

  return (
    <Layout
      pageData={{
        ...data,
        page,
      }}
    >
      <HeroBlock
        section={{
          section_id: "contributions-title",
          title: page.frontmatter.title,
          content:
            "Over the years, I've used various Open Source Software (OSS) projects either as part of work or for some personal projects and I've always made an effort to contribute back to them. These are all the little ways I've contributed back:",
        }}
      />
      <span className="no-border">
        <a href="https://github.com/rohit-gohri/">
          <Img
            title="Github Followers"
            src="https://img.shields.io/github/followers/rohit-gohri?color=%239371e6&label=rohit-gohri&style=flat-square"
          />
        </a>
      </span>
      <div className="post-feed">
        {onlyMerged.map(({ repo, contributions }, index) => {
          const url = `https://github.com/${repo.nameWithOwner}`;
          const prsUrl = `${url}/pulls?q=is%3Apr+author%3A${viewer}`;

          return (
            <div key={`${repo.nameWithOwner}-${index}`} className="post">
              <div className="post-inside">
                <header className="post-header">
                  <h3 className="post-title" id={`oss-${repo.nameWithOwner}`}>
                    <Link to={url} rel={`bookmark`} external>
                      <Icon icon={RepoIcon} /> {repo.nameWithOwner}
                    </Link>
                  </h3>
                </header>
                <div className="post-content">
                  <Link className="post-avatar" to={url}>
                    <Img
                      alt={repo.nameWithOwner}
                      className="thumbnail"
                      src={repo.owner.avatarUrl}
                    />
                  </Link>
                  <p className="post-description">
                    {truncate(repo.description, MAX_LENGTH)}
                  </p>
                </div>
                <footer className="post-meta">
                  <span>
                    {<Icon icon={StarIcon} />} Stars:{" "}
                    {repo.stargazers.totalCount}
                  </span>
                  <br />
                  <span>
                    {<Icon icon={GitPullRequestIcon} />}
                    <Link to={prsUrl}>
                      {" "}
                      My Pull Requests: {contributions.length}
                    </Link>
                  </span>
                </footer>
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
